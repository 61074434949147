import axios from "axios";




const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API endpoint URL
    headers: {
        "Content-Type": "application/json", // Set the default Content-Type header
    },
});

export default instance;