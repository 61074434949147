import * as ACTIONS from '../actions/actionTypes';

const initialState = {
    userData: {
        name: '',
        email: '',
        password: ''
    },
    token: null,
    // userId: null,
    isLoggedIn: false,
    errorMsg: null,
    authRedirectPath: '/'
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.SET_USER_FIELD:
            return {
                 ...state,
                 userData: {
                     ...state.userData,
                     [action.payload.name]: action.payload.value
                 }
            }
        case ACTIONS.USER_LOGOUT:
            return { ...state, token:null, isLoggedIn: false }
        case ACTIONS.USER_AUTH_SUCCESSFUL:
            return { ...state, token: action.payload.token, isLoggedIn: true, errorMsg: null }
        case ACTIONS.USER_AUTH_FAIL:
            return { ...state, isLoggedIn: false, errorMsg: action.payload.errorMsg }
        case ACTIONS.CLEAR_ERR_MSG:
            return { ...state, errorMsg: null }
        case ACTIONS.SET_AUTH_REDIRECT_PATH:
            return { ...state, authRedirectPath: action.payload.path }
        default:
            return { ...state }

    }
}

export default reducer;