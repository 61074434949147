// question related
export const FETCH_QUESTIONS_START = 'fetch_questions_start';
export const FETCH_QUESTIONS_SUCCESS = 'fetch_questions_success';
export const FETCH_QUESTIONS_FAIL = 'fetch_questions_fail'
export const FETCH_SINGLE_QUESTION_START = 'fetch_single_question_start'
export const FETCH_SINGLE_QUESTION_SUCCESS = 'fetch_single_question_success'
export const FETCH_SINGLE_QUESTION_FAIL = 'fetch_single_question_error'
export const POST_QUESTION_SUCCESS = 'post_question_success'
export const POST_QUESTION_FAIL = 'post_question_fail'
export const POST_QUESTION_MODAL_CLOSE = 'post_question_modal_close'
export const SET_QUESTION_FIELD = 'set_question_field'

// user related 
export const SET_USER_FIELD = 'set_user_field';
export const USER_LOGOUT = 'user_logout'
export const USER_AUTH_SUCCESSFUL = 'user_auth_successful'
export const USER_AUTH_FAIL = 'user_auth_fail'
export const CLEAR_ERR_MSG = 'clear_err_msg'
export const SET_AUTH_REDIRECT_PATH = 'set_auth_redirect_path'


// voting related
export const UPDATE_QUESTION_VOTE_SUCCESS = 'update_question_vote_success'
export const UPDATE_QUESTION_VOTE_FAIL = 'update_question_vote_fail'
export const ALREADY_VOTED = 'already_voted'
export const UPDATE_QUESTION_ANSWERS_STATE = 'update_question_answers_state'
export const ALREADY_VOTED_QUESTION_MODAL_CLOSE = 'already_voted_question_modal_close'
export const UPDATE_QUESTION_ANSWERS_VOTE_SUCCESS = 'update_question_answers_vote_success'
export const UPDATE_QUESTION_ANSWERS_VOTE_FAIL = 'update_question_answers_vote_fail'
export const ALREADY_VOTED_ANSWER = 'already_voted_answer'
export const POST_ANSWER_MODAL_CLOSE = 'post_answer_modal_close'
export const ALREADY_VOTED_ANSWER_MODAL_CLOSE = 'already_voted_answer_modal_close'


// answer related
export const POST_ANSWER_SUCCESS = 'post_answer_successs'
export const POST_ANSWER_FAIL = 'post_answer_fail'
export const SET_ANSWER_FIELD = 'set_answer_field'
export const RESET_ANSWER_STATE = 'reset_answer_state'


// social media login
export const FACEBOOK_LOGIN = 'facebook_login'

