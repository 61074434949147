import React from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Card from "./UI/Card";

// team Image link
import team1 from "../assets/images/teams/my-pic.jpg";
import team2 from '../assets/images/teams/arbind_edited.jpg'
import team3 from '../assets/images/teams/puja_edited.jpg'
import team4 from '../assets/images/teams/nabina.jpg'

export default function Aboutt() {
  return (
    <>
      <Header />
      <div className="container my-4">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Meet our Teams</h1>
            <hr />
            <p>We believe in what we do.</p>
          </div>
        </div>

        <div className="row">
          <Card name="Suraj Oberai" imageLink={team1} />
          <Card name="Arbin Shah" imageLink={team2} />
          <Card name="Puja Mudbhari" imageLink={team3} />
          <Card name="Nabina Pandey" imageLink={team4} />
        </div>
      </div>
      <Footer />
    </>
  );
}
