import * as ACTIONS from '../actions/actionTypes'

const initialstate = {
    answerData: {
        description: ''
    },
    error: false,
    errorMsg: false,
    posted: false,
    loading: false,
    isVoted: false
}

const reducer = (state = initialstate, action) => {
    switch(action.type) {
        case ACTIONS.POST_ANSWER_SUCCESS:
            return { ...state, posted: true, answerData: {
                ...state.answerData,
                description: ''
            } }
        case ACTIONS.POST_ANSWER_FAIL:
            return { ...state, posted: false }
        case ACTIONS.SET_ANSWER_FIELD:
            return {
                ...state,
                answerData: {
                    ...state.answerData,
                    [action.payload.name]: action.payload.value
                }
            }
        case ACTIONS.RESET_ANSWER_STATE:
            return { ...state, posted: false, error: false, errorMsg: false, loading: false }
        case ACTIONS.POST_ANSWER_MODAL_CLOSE:
            return { ...state, posted: false }
        default: 
            return { ...state }
    }
}

export default reducer;