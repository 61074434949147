import React, { useState, useEffect } from 'react'
import Footer from '../partials/Footer'
import Header from '../partials/Header'
import Spinner from '../UI/Spinner'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom'
import axios from "../../axios-config/config"

export default function MyAccount() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem('token')
    axios.get('/api/auth/getUserQuestionAnswers', {
      headers: {
        Authorization: token
      }
    })
      .then(res => {
        console.log(res.data);
        setUser(res.data.questionAnswers);
        setLoading(false);
      })
      .catch(err => console.log(err.response.data))

  }, [])

  return (
    <>
      <Header />
      {loading ? (
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Spinner />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row mt-4">
            <div className="col-12">
              <h3>
                {user.questions.length}{" "}
                {user.questions.length === 1 ? "Question" : "Questions"}
              </h3>
              <hr />
            </div>
          </div>
          <div className="row m-2">
            {/* question listing starts */}

            {user.questions && user.questions.map((question) => (
              <div className="col-12" key={question._id}>
                <NavLink
                  to={`/question/${question._id}`}
                  className="list-group-item list-group-item-action flex-column align-items-start"
                  key={question._id}
                >

                  <div className="d-flex justify-content-between">
                    <h5 className="mb-1">{question.title}</h5>
                    <div>
                      <FontAwesomeIcon icon={faArrowUp} />
                      <small>{question.upvote}</small>
                    </div>
                  </div>
                  <small className="text-muted">{question.createdAt.slice(0, 10)}</small>
                </NavLink>
              </div>
            ))}

          </div>

          {/* answers section starts */}
          <div className="row mt-4">
            <div className="col-12">
              <h3>
                {user.answers.length}{" "}
                {user.answers.length === 1 ? "Answer" : "Answers"}
              </h3>
              <hr />
            </div>
          </div>
          <div className="row m-2">
            {/* answer listing starts */}
            {user.answers.map((answer) => (
              <div className="col-12 shadow mt-2 p-4" key={answer._id}>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-1">{answer.description}</h5>
                  <div>
                    <FontAwesomeIcon icon={faArrowUp} />
                    <small>{answer.upvote}</small>
                  </div>
                </div>
                <small className="text-muted">{answer.createdAt.slice(0, 10)}</small>
              </div>
            ))}
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
