import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css';
import About from "./components/About";
import { connect } from 'react-redux';
// import dotenv from 'dotenv';


// added imports
import Home from './components/Home'
import JobList from './components/Jobs/JobList';
import Login from './components/User/Login';
import Register from './components/User/Register';
import AskQuestion from "./components/Question/AskQuestion";
import QuestionWithAnswers from "./components/Question/QuestionWithAnswers";
import Logout from './components/User/Logout';
import { checkAuthState } from './store/actions/users';
import MyAccount from './components/User/MyAccount';

// dotenv.config();


function App(props) {
  useEffect(() => {
    props.onTryAuthStatus();
    // eslint-disable-next-line
  }, [])
  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/user/login" exact component={Login} />
        <Route path="/user/register" exact component={Register} />
        <Route path="/jobs" exact component={JobList} />
        <Route path="/about" exact component={About} />
        <Route path="/questions/ask" exact component={AskQuestion} />
        <Route path="/question/:id" exact component={QuestionWithAnswers} />
        <Route path="/user/logout" exact component={Logout} />
        <Route path="/user/myaccount" exact component={MyAccount} />
      </Router>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    isLoggedIn: state.user.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAuthStatus: () => dispatch(checkAuthState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
