import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

import styles from "../../assets/styles/about.module.css";

export default function Card(props) {
  return (
    <div className="col-lg-3 col-md-6 col-12 text-center shadow p-2">
      <div className="teamImg">
        <img className={styles.teamImg} src={props.imageLink} alt="teamImg1" />
      </div>
      <h4 className="font-weight-bold mt-2">{props.name}</h4>
      <h6 className="mb-2">Student</h6>
      <div className="d-flex justify-content-center">
        <div className="m-4">
          <a href="#!">
            <FontAwesomeIcon icon={faFacebook} className={styles.iconSize} />
          </a>
        </div>
        <div className="m-4">
          <a href="#!">
            <FontAwesomeIcon icon={faInstagram} className={styles.iconSize} />
          </a>
        </div>
        <div className="m-4">
          <a href="#!">
            <FontAwesomeIcon icon={faGithub} className={styles.iconSize} />
          </a>
        </div>
      </div>
    </div>
  );
}
