import React from 'react'
import { NavLink } from 'react-router-dom';
import { Jumbotron, Button } from 'reactstrap';
import styles from '../assets/styles/hero.module.css'
import { connect } from 'react-redux'
import { setAuthRedirectPath } from '../store/actions/users';

function Hero(props) {
    const clickHandler = () => {
      if (!props.isLoggedIn) props.onSetAuthRedirectPath('/questions/ask')
    }

    return (
      <div >
        <Jumbotron className={styles.jumboleft}>
          <h1 className="display-3">Welcome to Mesla!</h1>
          <p className="lead">
            Join the community today! <br />
            Learn. Code. Interact.
          </p>
          <hr className="my-2" style={{width: "400px", float: 'left'}}/>
          <p style={{clear: 'both'}}>
            <strong>Login / Register to get started.</strong> 
          </p>
          <p className="lead">
            <NavLink to="/questions/ask">
            <Button onClick={clickHandler} className={styles.askQuestion}>Ask Question</Button>
            </NavLink>
          </p>
        </Jumbotron>
      </div>
    );
}
const mapStateToProps = state => {
  return {
    isLoggedIn: state.user.isLoggedIn
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirectPath: (path) => dispatch(setAuthRedirectPath(path))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Hero);