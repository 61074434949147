import React from "react";
import KhaltiCheckout from "khalti-checkout-web";
import axios from "axios";

export default function khalti() {
  let config = {
    // replace this key with yours
    publicKey: "test_public_key_d27b7e1e8bf54df4a64c57f28355fb35",
    productIdentity: "1234567890",
    productName: "Mesla Fundraising",
    productUrl: "http://localhost:3000/",
    eventHandler: {
      onSuccess(payload) {
        axios
          .get(
            `https://meslaforum.herokuapp.com/khalti/${payload.token}/${payload.amount}/test_secret_key_8c058cabe1474ed8b49effc29b895711`
          )
          .then((res) => {
            console.log(res)
            alert('Thank you for generosity.')
          })
          .catch((err) => {
            console.log(err.response)
            alert("Oops! Something went wrong.")
          });
      },
      // onError handler is optional
      onError(error) {
        // handle errors
        console.log(error);
      },
      onClose() {
        console.log("widget is closing");
      },
    },
    paymentPreference: [
      "KHALTI",
      "EBANKING",
      "MOBILE_BANKING",
      "CONNECT_IPS",
      "SCT",
    ],
  };

  let checkout = new KhaltiCheckout(config);

  const khaltiPay = () => {
    checkout.show({ amount: 1000 });
  };

  return (
    <div id="payment-button" onClick={khaltiPay}>
      <button
        className="btn btn-light"
        style={{ background: "#50336E", color: "white" }}
      >
        Donate Rs.10
      </button>
    </div>
  );
}
