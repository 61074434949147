import React from 'react'
import ReactDOM from 'react-dom'

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    padding: '50px',
    zIndex: 1000

}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

export default function Modal(props) {
    if (!props.open) return null;
    return ReactDOM.createPortal(
        <>
            <div style={OVERLAY_STYLES} onClick={props.onClose}/>
            <div style={MODAL_STYLES}>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-info" onClick={props.onClose}>X</button>
                </div>
                {props.children}
            </div>
        </>,
        document.getElementById('portal')
    )
}
