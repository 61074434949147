import React, { useEffect } from 'react'
import { NavLink, Redirect } from 'react-router-dom';
import Loginsvg from '../../assets/images/finalLogin.svg'
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import {connect } from 'react-redux'
import { clearErrorMsg, setUserfield, userLogin } from '../../store/actions/users';
import styles from '../../assets/styles/user.module.css'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";

function Login(props) {
    useEffect(() => {
      props.onClearErrorMsg()
      // eslint-disable-next-line
    },[])
    return (
      <>
        {props.isLoggedIn ? <Redirect to={props.authRedirectPath} /> : null}
        <Header />
        <div className="container my-4">
          <div className="row">
            <div className="col-12">
              <div className="header text-center">
                <strong>Login</strong>
              </div>
            </div>
            <div className="col-md-6 col-12 text-right">
              <div className="image">
                <img src={Loginsvg} alt="Login" width="400" />
              </div>
            </div>
            <div className="col-md-6 col-12 py-3">
              {props.errorMsg !== null ? (
                <div className="bg bg-danger p-1 py-2 text-white text-center">
                  <p>{props.errorMsg}</p>
                </div>
              ) : null}
              <div className="col-md-6 offset-2">
                <p className={`${styles.socialMediaLogin} shadow`}>
                  {" "}
                  <FontAwesomeIcon icon={faGoogle} style={{marginRight: '10px'}}/>
                  Login With Gmail
                </p>
                <p
                  className={`${styles.socialMediaLogin} shadow`}
                  style={{ background: "#6278a3", marginRight: '10px'}}
                >
                  <FontAwesomeIcon icon={faFacebook} style={{marginRight: '10px'}}/>
                  Login With Facebook
                </p>
              </div>

              <div className="form">
                <form
                  onSubmit={(e) =>
                    props.onSubmitLogin(e, props.email, props.password)
                  }
                >
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="email"
                      className="form-control"
                      onChange={(e) =>
                        props.onSetUserData(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Type your password here"
                      className="form-control"
                      onChange={(e) =>
                        props.onSetUserData(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-info">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12">
              <p className="mt-2 text-right">
                <NavLink to="/user/register"> Create New Account? </NavLink>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}

const mapStateToProps = state => {
  return {
    email: state.user.userData.email,
    password: state.user.userData.password,
    errorMsg: state.user.errorMsg,
    isLoggedIn: state.user.isLoggedIn,
    authRedirectPath: state.user.authRedirectPath
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onSetUserData: (name, value) => dispatch(setUserfield(name, value)),
    onSubmitLogin: (e, email, password) => dispatch(userLogin(e, email, password)),
    onClearErrorMsg: () => dispatch(clearErrorMsg())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);