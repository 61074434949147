import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Tag from "../UI/Tag";
import styles from "../../assets/styles/hero.module.css";
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import {
  fetchSingleQuestion,
  updateQuestionVote,
  updateQuestionAnswerVote,
  alreadyVotedAnswerModalClose,
  alreadyVotedQuestionModalClose
} from "../../store/actions/questions";
import Spinner from '../UI/Spinner'
import { setAnswerField, postAnswer, resetAnswerState, postAnswerModalClose } from "../../store/actions/answers";
import Modal from '../UI/Modal'
import { isAuthenticated } from "../../utils/auth";
import { useHistory } from "react-router-dom";

const QuestionWithAnswers = (props) => {
  const history = useHistory();
  let date;
  let tagsArr;
  if (props.question) {
    date = props?.question?.createdAt?.slice(0, 10)
    tagsArr = props?.question?.tags?.split(' ')
  }
  const id = props.match.params.id;
  useEffect(() => {
    props.onFetchSingleQuestion(props.match.params.id);
    props.onResetAnswerState();
    // eslint-disable-next-line
  }, [])

  const onCaretUpHandler = (id) => {
    if (isAuthenticated()) {
      props.onUpdateQuestionVote(
        id,
        props.question.upvote,
        props.isQuestionVoted,
        "inc"
      )
    } else {
      history.push('/user/login')
    }
  }

  const onCaretDownHandler = (id) => {
    if (isAuthenticated()) {
      props.onUpdateQuestionVote(
        id,
        props.question.upvote,
        props.isQuestionVoted,
        "dec"
      )
    } else {
      history.push('/user/login');
    }
  }

  const onAnswerUpHandler = (answer, index) => {
    if (isAuthenticated()) {
      props.onUpdateQuestionAnswerVote(
        answer?._id,
        answer?.upvote,
        index,
        props.isAnswerVoted,
        "inc"
      )
    } else {
      history.push('/user/login')
    }
  }

  const onAnswerDownHandler = (answer, index) => {
    if (isAuthenticated()) {
      props.onUpdateQuestionAnswerVote(
        answer?._id,
        answer?.upvote,
        index,
        props.isAnswerVoted,
        "dec"
      )
    } else {
      history.push('/user/login')
    }
  }

  const submitAnswer = (e) => {
    if (isAuthenticated()) {
      props.onSubmitAnswer(
        e,
        props.description,
        id,
        props.question
      )
    } else {
      history.push('/user/login')
    }
  }

  let content = (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    </div>
  );

  if (!props.loading) {
    content = (
      <>
        <div className="container-fluid p-4 shadow question">
          <div className="row text-center">
            <Modal
              open={props.msg}
              onClose={props.onAlreadyVotedQuestionModalClose}
            >
              <div className="col-12 d-flex justify-content-center">
                <div className="bg-danger text-white p-2">
                  <h5>You have already voted question.</h5>
                </div>
              </div>
            </Modal>
          </div>

          <div className="row offset-1">
            {props.loading ? (
              <div className="col-12 d-flex justify-content-center">
                <Spinner />
              </div>
            ) : null}

            <div className="col">
              <h4>
                {props.question !== null
                  ? props.question.title
                  : null}
                <a href="/questions/ask">
                  <Button
                    className={styles.askQuestion}
                    style={{ display: "flex", float: "right" }}
                  >
                    Ask Question
                  </Button>
                </a>
              </h4>
              <p>{date}</p>
              <hr />
            </div>
          </div>

          <div className="row m-2">
            <div className="col-2 text-right">
              <FontAwesomeIcon
                icon={faCaretUp}
                size="5x"
                color="#127EA6"
                onClick={() =>
                  onCaretUpHandler(id)
                }
                style={{ cursor: 'pointer' }}
              />

              <p className="m-2" style={{ fontSize: "24px" }}>
                {props.question !== null
                  ? props.question.upvote
                  : null}
              </p>
              <FontAwesomeIcon
                icon={faCaretDown}
                size="5x"
                color="#127EA6"
                onClick={() =>
                  onCaretDownHandler(id)
                }
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className="col-10 pt-4">
              {/* { props.question.description } */}
              {props.question !== null
                ? props.question.description
                : null}
            </div>
          </div>

          <div className="row offset-2">
            <div className="col-8 ">
              {tagsArr && tagsArr.map((tag) => <Tag tag={tag} key={tag} />)}
            </div>
            <div className="col-1 p-1 text-right">
              <FontAwesomeIcon icon={faUser} size="2x" />
            </div>
            <div className="col-3">
              <p className="m-0">
                {props.question !== null
                  ? props.question.user.name
                  : null}
              </p>
              {date}
            </div>
          </div>
        </div>

        {/* answer section */}
        <div className="container m-4 answer">
          {/* answer header */}
          <div className="row offset-1">
            <div className="col">
              <h3>
                {props.question !== null
                  ? props.question.answers.length
                  : null}{" "}
                Answers
              </h3>
              <hr />
            </div>
            <Modal
              open={props.ansMsg !== null}
              onClose={props.onAlreadyVotedAnswerModalClose}
            >
              <div className="bg-danger text-white p-2">
                <h5>You have already voted answer?.</h5>
              </div>
            </Modal>
          </div>

          {/* answer list */}
          {props.question !== null
            ? props.answers.map((answer, index) => (
              <div className="row m-2" key={index}>
                <div className="col-2 text-right">
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    size="3x"
                    color="#127EA6"
                    onClick={() =>
                      onAnswerUpHandler(answer, index)
                    }
                    style={{ cursor: 'pointer' }}
                  />
                  <p className="m-2">{answer?.upvote}</p>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size="3x"
                    color="#127EA6"
                    onClick={() =>
                      onAnswerDownHandler(answer, index)
                    }
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className="col-10">
                  <p>{answer?.description}</p>
                </div>
                <div className="col-10 text-right pr-0">
                  <FontAwesomeIcon icon={faUser} size="2x" />
                </div>
                <div className="col-2">
                  <p className="mb-0">{answer?.user?.name}</p>
                  <p>{answer?.user.createdAt?.slice(0, 10)}</p>
                </div>
                <div className="col-10 offset-2">
                  <hr />
                </div>
              </div>
            ))
            : null}

          {/* post answer */}
          <div className="row">
            <Modal
              open={props.posted}
              onClose={props.onPostAnswerModalClose}
            >
              <div>
                <h5>Your answer has been posted !!!.</h5>
              </div>
            </Modal>
            <div className="col-10 offset-2 text-center">
              <form
                onSubmit={(e) =>
                  submitAnswer(e)
                }
              >
                <div className="form-group">
                  <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="10"
                    className="form-control"
                    value={props.description}
                    onChange={(e) =>
                      props.onSetAnswerField(
                        e.target.name,
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className={`form-control ${styles.postAnswerButton}`}
                  >
                    Post Answer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <Header />
      {/* question section */}
      {content}
      <Footer />
    </div>
  )
}

// class QuestionWithAnswers extends React.Component {


//   componentDidMount() {
//     props.onFetchSingleQuestion(props.match.params.id);
//     props.onResetAnswerState();
//   }

//   onCaretUpHandler(id) {
//     if (isAuthenticated()) {
//       props.onUpdateQuestionVote(
//         id,
//         props.question.upvote,
//         props.isQuestionVoted,
//         "inc"
//       )
//     } else {
//       history.push('/')
//     }
//   }

//   render() {
//     let date;
//     let tagsArr;
//     if (props.question) {
//       date = this.props?.question?.createdAt?.slice(0, 10)
//       tagsArr = this.props?.question?.tags?.split(' ')
//     }
//     const id = props.match.params.id;
//     const history = useHistory();
//     let content = (
//       <div className="container">
//         <div className="row">
//           <div className="col-12 text-center">
//             <Spinner />
//           </div>
//         </div>
//       </div>
//     )
//     if (!props.loading) {
//       content = (
//         <>
//           <div className="container-fluid p-4 shadow question">
//             <div className="row text-center">
//               <Modal
//                 open={props.msg}
//                 onClose={props.onAlreadyVotedQuestionModalClose}
//               >
//                 <div className="col-12 d-flex justify-content-center">
//                   <div className="bg-danger text-white p-2">
//                     <h5>You have already voted question.</h5>
//                   </div>
//                 </div>
//               </Modal>
//             </div>

//             <div className="row offset-1">
//               {props.loading ? (
//                 <div className="col-12 d-flex justify-content-center">
//                   <Spinner />
//                 </div>
//               ) : null}

//               <div className="col">
//                 <h4>
//                   {props.question !== null
//                     ? props.question.title
//                     : null}
//                   <a href="/questions/ask">
//                     <Button
//                       className={styles.askQuestion}
//                       style={{ display: "flex", float: "right" }}
//                     >
//                       Ask Question
//                     </Button>
//                   </a>
//                 </h4>
//                 <p>{date}</p>
//                 <hr />
//               </div>
//             </div>

//             <div className="row m-2">
//               <div className="col-2 text-right">
//                 <a href="#!">
//                   <FontAwesomeIcon
//                     icon={faCaretUp}
//                     size="5x"
//                     color="#127EA6"
//                     onClick={() =>
//                       this.onCaretUpHandler(id)
//                     }
//                   />
//                 </a>

//                 <p className="m-2" style={{ fontSize: "24px" }}>
//                   {props.question !== null
//                     ? props.question.upvote
//                     : null}
//                 </p>
//                 <a href="#!">
//                   <FontAwesomeIcon
//                     icon={faCaretDown}
//                     size="5x"
//                     color="#127EA6"
//                     onClick={() =>
//                       props.onUpdateQuestionVote(
//                         id,
//                         props.question.upvote,
//                         props.isQuestionVoted,
//                         "dec"
//                       )
//                     }
//                   />
//                 </a>
//               </div>
//               <div className="col-10 pt-4">
//                 {/* { props.question.description } */}
//                 {props.question !== null
//                   ? props.question.description
//                   : null}
//               </div>
//             </div>

//             <div className="row offset-2">
//               <div className="col-8 ">
//                 {tagsArr && tagsArr.map((tag) => <Tag tag={tag} key={tag} />)}
//               </div>
//               <div className="col-1 p-1 text-right">
//                 <FontAwesomeIcon icon={faUser} size="2x" />
//               </div>
//               <div className="col-3">
//                 <p className="m-0">
//                   {props.question !== null
//                     ? props.question.user.name
//                     : null}
//                 </p>
//                 {date}
//               </div>
//             </div>
//           </div>

//           {/* answer section */}
//           <div className="container m-4 answer">
//             {/* answer header */}
//             <div className="row offset-1">
//               <div className="col">
//                 <h3>
//                   {props.question !== null
//                     ? props.question.answers.length
//                     : null}{" "}
//                   Answers
//                 </h3>
//                 <hr />
//               </div>
//               <Modal
//                 open={props.ansMsg !== null}
//                 onClose={props.onAlreadyVotedAnswerModalClose}
//               >
//                 <div className="bg-danger text-white p-2">
//                   <h5>You have already voted answer?.</h5>
//                 </div>
//               </Modal>
//             </div>

//             {/* answer list */}
//             {props.question !== null
//               ? props.answers.map((answer, index) => (
//                 <div className="row m-2" key={index}>
//                   <div className="col-2 text-right">
//                     <a href="#!">
//                       <FontAwesomeIcon
//                         icon={faCaretUp}
//                         size="3x"
//                         color="#127EA6"
//                         onClick={() =>
//                           props.onUpdateQuestionAnswerVote(
//                             answer?._id,
//                             answer?.upvote,
//                             index,
//                             props.isAnswerVoted,
//                             "inc"
//                           )
//                         }
//                       />
//                     </a>
//                     <p className="m-2">{answer?.upvote}</p>
//                     <a href="#!">
//                       <FontAwesomeIcon
//                         icon={faCaretDown}
//                         size="3x"
//                         color="#127EA6"
//                         onClick={() =>
//                           props.onUpdateQuestionAnswerVote(
//                             answer?._id,
//                             answer?.upvote,
//                             index,
//                             props.isAnswerVoted,
//                             "dec"
//                           )
//                         }
//                       />
//                     </a>
//                   </div>
//                   <div className="col-10">
//                     <p>{answer?.description}</p>
//                   </div>
//                   <div className="col-10 text-right pr-0">
//                     <FontAwesomeIcon icon={faUser} size="2x" />
//                   </div>
//                   <div className="col-2">
//                     <p className="mb-0">{answer?.user?.name}</p>
//                     <p>{answer?.user.createdAt?.slice(0, 10)}</p>
//                   </div>
//                   <div className="col-10 offset-2">
//                     <hr />
//                   </div>
//                 </div>
//               ))
//               : null}

//             {/* post answer */}
//             <div className="row">
//               <Modal
//                 open={props.posted}
//                 onClose={props.onPostAnswerModalClose}
//               >
//                 <div>
//                   <h5>Your answer has been posted !!!.</h5>
//                 </div>
//               </Modal>
//               <div className="col-10 offset-2 text-center">
//                 <form
//                   onSubmit={(e) =>
//                     props.onSubmitAnswer(
//                       e,
//                       props.description,
//                       id,
//                       props.question
//                     )
//                   }
//                 >
//                   <div className="form-group">
//                     <textarea
//                       name="description"
//                       id="description"
//                       cols="30"
//                       rows="10"
//                       className="form-control"
//                       value={props.description}
//                       onChange={(e) =>
//                         props.onSetAnswerField(
//                           e.target.name,
//                           e.target.value
//                         )
//                       }
//                     ></textarea>
//                   </div>
//                   <div className="form-group">
//                     <button
//                       type="submit"
//                       className={`form-control ${styles.postAnswerButton}`}
//                     >
//                       Post Answer
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </>
//       );
//     }

//     return (
//       <div>
//         <Header />
//         {/* question section */}
//         {content}
//         <Footer />
//       </div>
//     );
//   }
// }

const mapStateToProps = state => {
  return {
    question: state.question.singleQuestion,
    loading: state.question.loading,
    isQuestionVoted: state.question.votedQuestion,
    isAnswerVoted: state.question.votedAnswer,
    msg: state.question.questionMsg,
    description: state.answer?.answerData.description,
    posted: state.answer?.posted,
    answers: state.question.answers,
    ansMsg: state.question.answerMsg
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchSingleQuestion: (id) => dispatch(fetchSingleQuestion(id)),
    onUpdateQuestionVote: (id, vote, isVoted, type) => dispatch(updateQuestionVote(id, vote, isVoted, type)),
    onSetAnswerField: (name, value) => dispatch(setAnswerField(name, value)),
    onSubmitAnswer: (e, description, id, question) => dispatch(postAnswer(e, description, id, question)),
    onResetAnswerState: () => dispatch(resetAnswerState()),
    onUpdateQuestionAnswerVote: (id, vote, index, isVoted, type) => dispatch(updateQuestionAnswerVote(id, vote, index, isVoted, type)),
    onPostAnswerModalClose: () => dispatch(postAnswerModalClose()),
    onAlreadyVotedAnswerModalClose: () => dispatch(alreadyVotedAnswerModalClose()),
    onAlreadyVotedQuestionModalClose: () => dispatch(alreadyVotedQuestionModalClose())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestionWithAnswers);