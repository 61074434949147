import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// added imports
import 'bootstrap/dist/css/bootstrap.css';


// redux management
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'

import userReducer from './store/reducers/users'
import questionReducer from './store/reducers/questions'
import answerReducer from './store/reducers/answers'

const rootReducer = combineReducers({
  user: userReducer,
  question: questionReducer,
  answer: answerReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))


ReactDOM.render(
  // replaced <React.StrictMode> by <React.Fragment>
  <Provider store={store}>
    <React.Fragment>    
        <App />
      </React.Fragment>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
