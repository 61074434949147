import React, {useState} from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
  } from 'reactstrap';
import { NavLink } from 'react-router-dom'
import styles from '../../assets/styles/header.module.css'
import { connect } from 'react-redux'
import Khalti from '../Khalti/Khalti'


function Header(props) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
      <div className={styles.navColor}>
        <Navbar color="#9ceaef" light expand="md">
          <NavLink to="/" className={styles.navBrand}>
            <img src={require('../../assets/images/finalLogo.png')} alt="we did it!!!" height="100"/>
          </NavLink>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem className={styles.navLink}>
                <NavLink to="/" exact={true} className={styles.navLink} activeClassName={styles.isActive}>Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/about" exact={true} className={styles.navLink} activeClassName={styles.isActive}>
                  About
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/jobs" exact={true} activeClassName={styles.isActive} className={styles.navLink}>
                  Jobs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/user/myaccount" exact={true} activeClassName={styles.isActive} className={styles.navLink}>
                  My account
                </NavLink>
              </NavItem>
            </Nav>
            <Khalti />  
            {props.isLoggedIn 
            ? 
            <NavLink to="/user/logout" exact={true} activeClassName={styles.isActive} className={styles.navLink}>
            <div className="btn btn-light">
              Logout
            </div>
          </NavLink>
          
          :
          <NavLink to="/user/login" exact={true} activeClassName={styles.isActive} className={styles.navLink}>
          <div className="btn btn-light">
            Login/Register
          </div>
        </NavLink>
        }
          
          </Collapse>
        </Navbar>
      </div>
    );
}
const mapStateToProps = state => {
  return {
    isLoggedIn: state.user.isLoggedIn
  }
}

export default connect(mapStateToProps)(Header);