import React from 'react'
import '../../assets/styles/tags.css'

export default function Tag({btnType, tag}) {
    const btnTypeArr = ['python', 'matlab', 'postgresql', 'laravel', 'jquery', 'node_js', 'ruby', 'kotlin', 'HTML5', 'logo']
    const randomNumber = Math.floor((Math.random() * 10));
    return (
    <button className={`btn ${btnTypeArr[randomNumber]} ml-1`}>{tag}</button>
    )
}
