import * as ACTIONS from '../actions/actionTypes'

const initialstate = {
    questions: null,
    loading: false,
    error: false,
    singleQuestion: null,
    questionData: {
        title: '',
        description: '',
        tags: '',
        file: null
    },
    posted: false,
    updated: false,
    votedQuestion: false,
    questionMsg: null,
    answers: null,
    votedAnswer: false,
    answerMsg: null
}

const reducer = (state = initialstate, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_QUESTIONS_START:
        return { ...state, loading: true };
      case ACTIONS.FETCH_QUESTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          questions: action.payload.questions,
        };
      case ACTIONS.FETCH_QUESTIONS_FAIL:
        return { ...state, error: true, loading: false };
      case ACTIONS.FETCH_SINGLE_QUESTION_START:
        return { ...state, loading: true };
      case ACTIONS.FETCH_SINGLE_QUESTION_SUCCESS:
        return {
          ...state,
          loading: false,
          singleQuestion: action.payload.question,
          posted: false,
          answers: action.payload.question.answers,
          votedQuestion: false,
          votedAnswer: false
        };
      case ACTIONS.FETCH_SINGLE_QUESTION_FAIL:
        return { ...state, loading: false, error: true };
      case ACTIONS.POST_QUESTION_SUCCESS:
        return {
          ...state,
          error: false,
          posted: true,
          questionData: {
            ...state.questionData,
            title: "",
            description: "",
            tags: "",
          },
        };
      case ACTIONS.POST_QUESTION_FAIL:
        return { ...state, error: true, posted: false };
      case ACTIONS.SET_QUESTION_FIELD:
        return {
          ...state,
          questionData: {
            ...state.questionData,
            [action.payload.name]: action.payload.value,
          },
        };
      case ACTIONS.UPDATE_QUESTION_VOTE_SUCCESS:
        return {
          ...state,
          updated: true,
          singleQuestion: {
            ...state.singleQuestion,
            upvote: action.payload.updatedVote,
          },
          votedQuestion: true,
        };
      case ACTIONS.UPDATE_QUESTION_VOTE_FAIL:
        return {
          ...state,
          updated: false,
          error: true,
          votedQuestion: false,
        };
      case ACTIONS.ALREADY_VOTED:
        return {
          ...state,
          questionMsg: action.payload.msg,
        };
      case ACTIONS.UPDATE_QUESTION_ANSWERS_STATE:
        return {
          ...state,
          answers: [...state.answers, action.payload.data],
        };
      case ACTIONS.UPDATE_QUESTION_ANSWERS_VOTE_SUCCESS:
        const updatedAnswersArray = [...state.answers];
        updatedAnswersArray[action.payload.index].upvote =
          action.payload.updatedVote;
        return {
          ...state,
          answers: updatedAnswersArray,
          votedAnswer: true,
        };
      case ACTIONS.UPDATE_QUESTION_ANSWERS_VOTE_FAIL:
        return {
          ...state,
          error: true,
          votedAnswer: false,
        };
      case ACTIONS.ALREADY_VOTED_ANSWER:
        return {
          ...state,
          answerMsg: action.payload.msg,
        };
      case ACTIONS.POST_QUESTION_MODAL_CLOSE:
        return { ...state, posted: false };
      case ACTIONS.ALREADY_VOTED_ANSWER_MODAL_CLOSE:
        return { ...state, answerMsg: null };
      case ACTIONS.ALREADY_VOTED_QUESTION_MODAL_CLOSE:
        return { ...state, questionMsg: null };
      default:
        return { ...state };
    }
}

export default reducer;