import * as ACTIONS from '../actions/actionTypes'
import axios from "../../axios-config/config";

export const setUserfield = (name, value) => {
    return {
        type: ACTIONS.SET_USER_FIELD,
        payload: {
            name,
            value
        }
    }
}

export const userAuthSuccess = (token) => {
    return {
        type: ACTIONS.USER_AUTH_SUCCESSFUL,
        payload: {
            token
        }
    }
}
export const userAuthFail = (errorMsg) => {
    return {
        type: ACTIONS.USER_AUTH_FAIL,
        payload: {
            errorMsg
        }
    }
}


export const userRegister = (e, name, email, password) => {
    e.preventDefault();
    return dispatch => {
        axios.post('/api/auth/register', { name, email, password })
            .then(res => {
                console.log(res.data);
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('expirationDate', res.data.expiresIn)
                dispatch(userAuthSuccess(res.data.token))
                dispatch(checkAuthTimeOut(res.data.expiresIn.slice(0, 2)))
            })
            .catch(err => {
                console.log(err.response.data, 'getting errors while register in')
                dispatch(userAuthFail(err.response.data.msg))
            })
    }
}

export const checkAuthTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(userLogout());
        }, expirationTime * 43200 * 100)
    }
}


export const userLogin = (e, email, password) => {
    e.preventDefault();
    console.log(email, password);
    return dispatch => {
        axios.post('/api/auth/login', { email, password })
            .then(res => {
                console.log(res.data);
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('expirationDate', res.data.expiresIn)
                dispatch(userAuthSuccess(res.data.token))
                dispatch(checkAuthTimeOut(res.data.expiresIn.slice(0, 2)))
                // console.log(res.data.expiresIn.slice(0,2))
            })
            .catch(err => {
                console.log(err.response.data, 'getting errors while loggin in')
                dispatch(userAuthFail(err.response.data.msg))
            })
    }
}

export const userLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate')
    return {
        type: ACTIONS.USER_LOGOUT
    }
}

export const clearErrorMsg = () => {
    return {
        type: ACTIONS.CLEAR_ERR_MSG
    }
}

export const checkAuthState = () => {
    return dispatch => {
        const token = localStorage.getItem('token')
        if (!token) {
            dispatch(userLogout())
        } else {
            dispatch(userAuthSuccess(token))
        }
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: ACTIONS.SET_AUTH_REDIRECT_PATH,
        payload: {
            path
        }
    }
}

export const facebookLogin = () => {
    console.log('clicked')
    return dispatch => {
        axios.get('http://localhost:7655/api/auth/facebook')
            .then(res => console.log("then block", res))
            .catch(err => console.log(err.response))
    }
}