import React from "react";
import { connect } from 'react-redux'
import Footer from "../partials/Footer";
import Header from "../partials/Header";

import "../../assets/styles/question.css";
import { Redirect } from "react-router-dom";
import { postQuestion, postQuestionModalClose, setQuestionField } from "../../store/actions/questions";
import Modal from '../UI/Modal'

const Example = (props) => {


  // const submitForm = (e) => {
  //   e.preventDefault();
  //   console.log("from sub", props.fileName);
  //   const formData = new FormData();
  //   formData.append('title', props.title);
  //   formData.append('desciption', props.description);
  //   formData.append('tags', props.tags);
  //   formData.append('fileName', props.fileName)

  //   Axios.post('http://localhost:7655/upload', formData)
  //     .then(res => console.log("it's working"))
  //     .catch(err => console.log(err))

  // }

  return (
    <>
      {props.isLoggedIn !== true ? <Redirect to="/user/login" /> : null}
      <Header />
      <div className="container d-flex justify-content-center shadow my-3 py-4">
        <Modal open={props.posted} onClose={props.onPostQuestionModalClose}>
          <div>
            <h5>Congrats! Your question has been posted.</h5>
          </div>
        </Modal>
        {props.posted && <Redirect to="/" />}
        <div className="row d-flex justify-content-center">
          <div className="col-12 text-center">
            <h1>Fill the information of Question</h1>
          </div>
          <div className="col">
            <form
              onSubmit={(e) => props.onPostQuestion(e, props.title, props.description, props.tags, props.file)}
            >
              <div className="form-group">
                <label htmlFor="title">Title :</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="How can i upload image in React.js ?"
                  className="form-control"
                  value={props.title}
                  onChange={(e) =>
                    props.onSetQuestionField(e.target.name, e.target.value)
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">Description :</label>
                <textarea
                  type="text"
                  id="description"
                  placeholder="Fill the description of question"
                  className="form-control"
                  name="description"
                  value={props.description}
                  onChange={(e) =>
                    props.onSetQuestionField(e.target.name, e.target.value)
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="code">CODE :</label>
                <textarea
                  type="text"
                  id="code"
                  placeholder="put in your code here"
                  className="form-control"
                  name="code"
                />
              </div>

              <div className="form-group">
                <label htmlFor="tags">TAGS :</label>
                <input
                  type="text"
                  id="tags"
                  placeholder="python javascript node.js react.js"
                  className="form-control"
                  name="tags"
                  value={props.tags}
                  onChange={(e) =>
                    props.onSetQuestionField(e.target.name, e.target.value)
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlFile1">
                  Upload File (optional)
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  id="file"
                  name="file"
                  onChange={(e) => props.onSetQuestionField(e.target.name, e.target.files[0])}
                  accept=".jpg"
                />
              </div>

              <div className="col text-center">
                <button type="submit" className="btn">
                  Post Question
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToprops = state => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    title: state.question.questionData.title,
    description: state.question.questionData.description,
    tags: state.question.questionData.tags,
    file: state.question.questionData.file,
    posted: state.question.posted
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onPostQuestion: (e, title, description, tags, file) => dispatch(postQuestion(e, title, description, tags, file)),
    onSetQuestionField: (name, value) => dispatch(setQuestionField(name, value)),
    onPostQuestionModalClose: () => dispatch(postQuestionModalClose())
  }
}

export default connect(mapStateToprops, mapDispatchToProps)(Example);