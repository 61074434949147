import axios from "../../axios-config/config";
import * as ACTIONS from '../actions/actionTypes'

export const postAnswerSuccess = () => {
    return {
        type: ACTIONS.POST_ANSWER_SUCCESS
    }
}

export const postAnswerFail = () => {
    return {
        type: ACTIONS.POST_ANSWER_FAIL
    }
}

export const setAnswerField = (name, value) => {
    return {
        type: ACTIONS.SET_ANSWER_FIELD,
        payload: {
            name,
            value
        }
    }
}


export const updateQuestionAnswersState = (data) => {
    return {
        type: ACTIONS.UPDATE_QUESTION_ANSWERS_STATE,
        payload: {
            data
        }
    }
}

export const postAnswer = (e, description, questionId, question) => {
    const token = localStorage.getItem('token')
    e.preventDefault();
    const answersId = question.answers.map(answer => answer._id)
    const ansData = {
        description,
        question: questionId
    }
    return dispatch => {
        axios
            .post("/api/answers", ansData, {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                console.log(res.data, "stored answer in databases")
                answersId.push(res.data.data._id)
                const answerToBeAdded = {
                    upvote: res.data.data.upvote,
                    description: res.data.data.description,
                    createdAt: res.data.data.createdAt,
                    _id: res.data.data._id,
                    user: {
                        name: res.data.data.user.name,
                        _id: res.data.data.user._id
                    }
                }
                const userWhoPostedAnswerId = res.data.data.user._id;
                let userAnswersIdList = res.data.data.user.answers
                userAnswersIdList.push(res.data.data._id)
                axios.put(`/api/questions/${questionId}`, { answers: answersId }, {
                    headers: {
                        Authorization: token,
                    },
                })
                    .then(response => {
                        console.log(response.data, 'Updated questions with answers')
                        axios.put(`/api/auth/${userWhoPostedAnswerId}`, { answers: userAnswersIdList }, {
                            headers: {
                                Authorization: token
                            }
                        })
                            .then(res => {
                                console.log(res.data, "Updated user with answers")
                                dispatch(postAnswerSuccess())
                                dispatch(updateQuestionAnswersState(answerToBeAdded))
                            })
                            .catch(err => {
                                console.log(err.response.data)
                                dispatch(postAnswerFail())
                            })
                    })
                    .catch(err => {
                        console.log(err.response.data)
                    })
            })
            .catch((err) => console.log(err.response.data));
    }
}

export const resetAnswerState = () => {
    return {
        type: ACTIONS.RESET_ANSWER_STATE
    }
}

export const postAnswerModalClose = () => {
    return {
        type: ACTIONS.POST_ANSWER_MODAL_CLOSE
    }
}