import React, { useState } from 'react'
import { Link, NavLink, Redirect } from 'react-router-dom'
import RegisterSvg from '../../assets/images/finalregister.svg'
// import "../../assets/styles/user.scss"
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import { connect } from 'react-redux'
import { setUserfield, userRegister, facebookLogin } from '../../store/actions/users';
import styles from '../../assets/styles/user.module.css'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Register(props) {

  const checkPasswordMatch = (p1, p2) => {
    if (p1 === p2) {
      return true;
    }
    else return false
  }

  const [confirmPassword, setConfirmPassword] = useState("");




  const onRegisterClick = (e) => {

    if (checkPasswordMatch(props.password, confirmPassword)) {
      props.onSubmitRegister(e, props.name, props.email, props.password);
    } else {
      e.preventDefault();
      console.log("Oops! password don't match");
    }

  }

  return (
    <>
      {props.isLoggedIn ? <Redirect to={props.authRedirectPath} /> : null}
      <Header />
      <div className="container mt-4">
        <div className="row">
          <div className="col-12">
            <div className="header text-center">Register</div>
          </div>
          <div className="col-md-6 col-12">
            <div className="image">
              <img src={RegisterSvg} alt="Register" width="400" />
            </div>
          </div>
          <div className="col-md-6 col-12">
            {props.errorMsg !== null ? props.errorMsg : null}
            <div className="col-md-6 offset-2">
              <p className={`${styles.socialMediaLogin} shadow`}>
                {" "}
                <FontAwesomeIcon icon={faGoogle} style={{ marginRight: '10px' }} />
                Sign Up With Gmail
              </p>

              <Link to="/auth/facebook">
                <p
                  className={`${styles.socialMediaLogin} shadow`}
                  style={{ background: "#6278a3", marginRight: '10px' }}
                  onClick={props.onFacebookLogin}
                >
                  <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '10px' }} />
                  Sign Up With Facebook
                </p>
              </Link>





            </div>
            <div className="form">
              <form onSubmit={(e) => onRegisterClick(e)}>
                <div className="form-group">
                  <label htmlFor="name">Username</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="name"
                    value={props.name}
                    onChange={(e) => props.onSetUserField(e.target.name, e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={props.email}
                    onChange={(e) => props.onSetUserField(e.target.name, e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={props.password}
                    placeholder="Type your password here"
                    onChange={(e) => props.onSetUserField(e.target.name, e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cpassword">Confirm Password</label>
                  <input
                    type="password"
                    name="cpassword"
                    placeholder="Confirm your password"
                    className="form-control"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn mb-3">
                  Register
                </button>
              </form>
            </div>
          </div>
          <div className="col-12">
            <p className="mt-2 text-right">
              <NavLink to="/user/login">Switch to Login?</NavLink>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = state => {
  return {
    name: state.user.userData.name,
    email: state.user.userData.email,
    password: state.user.userData.password,
    isLoggedIn: state.user.isLoggedIn,
    errorMsg: state.user.errorMsg,
    authRedirectPath: state.user.authRedirectPath
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmitRegister: (e, name, email, password) => dispatch(userRegister(e, name, email, password)),
    onSetUserField: (name, value) => dispatch(setUserfield(name, value)),
    onFacebookLogin: () => dispatch(facebookLogin())
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Register);