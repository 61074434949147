import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import Question from './Question'
import Spinner from '../UI/Spinner'
import Pagination from '../UI/Pagination'


function QuestionList(props) {

  // eslint-disable-next-line
  const [questionPerPage, setQuestionPerPage] = useState(5);
  const [page, setPage] = useState(1)

  const paginate = (page) => {
    setPage(page)
  }
  let slicedQuestionArr;
  let hasNextPage;
    if (props.questions) {
        const updatedQuestionsArr = [...props.questions];
        const indexOfLastQuestion = page * questionPerPage;
        const indexOfFirstQuestion = indexOfLastQuestion - questionPerPage;
        slicedQuestionArr = updatedQuestionsArr.slice(
          indexOfFirstQuestion,
          indexOfLastQuestion
        );
        hasNextPage =
          !(
            props.questions.length >= indexOfFirstQuestion &&
            props.questions.length <= indexOfLastQuestion
          ) && indexOfFirstQuestion < props.questions.length;

    }
    return (
      <div className="container-fluid">
        <div className="row ">
          {props.loading ? (
            <div className="col-12 d-flex justify-content-center">
              <Spinner />
            </div>
          ) : null}

          {slicedQuestionArr &&
            slicedQuestionArr.map((question) => (
              <div className="col-12" key={question._id}>
                <NavLink to={`/question/${question._id}`}>
                  <Question
                    upvote={question.upvote}
                    downvote={question.downvote}
                    title={question.title}
                    date={question.createdAt}
                    userName={question.user.name}
                    answersLength={question.answers.length}
                    tags={question.tags}
                  />
                </NavLink>
              </div>
            ))}
            <div className="col-12 mt-2 d-flex justify-content-center">
            {slicedQuestionArr && <Pagination
              paginate={paginate}
              totalQuestions={props.questions.length}
              commentPerPage={questionPerPage}
              page={page}
              hasNextPage={hasNextPage}
            />}
            </div>
            
        </div>
      </div>
    );
}

const mapStateToProps = state => {
  return {
    questions: state.question.questions,
    loading: state.question.loading
  }
}

export default connect(mapStateToProps)(QuestionList);