import * as ACTIONS from './actionTypes'
import axios from "../../axios-config/config";


// action creators related to fetching questions

export const fetchQuestionsStart = () => {
    return {
        type: ACTIONS.FETCH_QUESTIONS_START
    }
}

export const fetchQuestionsSuccess = (questions) => {
    return {
        type: ACTIONS.FETCH_QUESTIONS_SUCCESS,
        payload: {
            questions
        }
    }
}

export const fetchQuestionsFail = () => {
    return {
        type: ACTIONS.FETCH_QUESTIONS_FAIL
    }
}

export const fetchQuestions = () => {
    return dispatch => {
        dispatch(fetchQuestionsStart())
        axios
            .get("/api/questions")
            .then((res) => {
                dispatch(fetchQuestionsSuccess(res.data.data));
            })
            .catch((err) => {
                dispatch(fetchQuestionsFail());
            });

    }
}

export const fetchSingleQuestionStart = () => {
    return {
        type: ACTIONS.FETCH_SINGLE_QUESTION_START
    }
}

export const fetchSingleQuestionSuccess = (question) => {
    return {
        type: ACTIONS.FETCH_SINGLE_QUESTION_SUCCESS,
        payload: {
            question
        }
    }
}

export const fetchSingleQuestionFail = () => {
    return {
        type: ACTIONS.FETCH_SINGLE_QUESTION_FAIL
    }
}

export const fetchSingleQuestion = (id) => {
    return dispatch => {
        dispatch(fetchSingleQuestionStart());
        axios.get(`/api/questions/${id}`)
            .then(res => {
                dispatch(fetchSingleQuestionSuccess(res.data.data))
            })
            .catch(err => {
                console.log("here is the error", err)
                dispatch(fetchSingleQuestionFail())
            })
    }
}

// actions creators related to posting questions
export const postQuestionSuccess = () => {
    return {
        type: ACTIONS.POST_QUESTION_SUCCESS
    }
}

export const postQuestionFail = () => {
    return {
        type: ACTIONS.POST_QUESTION_FAIL
    }
}

export const setQuestionField = (name, value) => {
    return {
        type: ACTIONS.SET_QUESTION_FIELD,
        payload: {
            name,
            value
        }
    }
}

export const postQuestion = (e, title, description, tags, file) => {

    const token = localStorage.getItem('token')
    e.preventDefault();
    var formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('tags', tags);
    formData.append('file', file);
    console.log(formData)

    return dispatch => {
        axios
            .post(
                "/api/questions",
                { title, description, tags },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                let questionsIdList = res.data.data.user.questions;
                questionsIdList.push(
                    res.data.data._id
                );
                axios
                    .put(
                        `/api/auth/${res.data.data.user._id}`,
                        { questions: questionsIdList },
                        {
                            headers: {
                                Authorization: token,
                            },
                        }
                    )
                    .then((res) => {
                        dispatch(postQuestionSuccess());
                    })
                    .catch((err) => {
                        console.log(err.response.data);
                    });
            })
            .catch((err) => {
                console.log(err.response.data);
                dispatch(postQuestionFail());
            });
    }
}

export const updatedQuestionVoteSuccess = (updatedVote) => {
    return {
        type: ACTIONS.UPDATE_QUESTION_VOTE_SUCCESS,
        payload: {
            updatedVote
        }
    }
}

export const updatedQuestionVoteFail = () => {
    return {
        type: ACTIONS.UPDATE_QUESTION_VOTE_FAIL
    }
}

export const alreadyVoted = (msg) => {
    return {
        type: ACTIONS.ALREADY_VOTED,
        payload: {
            msg
        }
    }
}

export const alreadyVotedAnswer = (msg) => {
    return {
        type: ACTIONS.ALREADY_VOTED_ANSWER,
        payload: {
            msg
        }
    }
}

export const updateQuestionVote = (id, vote, isVoted, type) => {
    if (!isVoted) {
        const token = localStorage.getItem('token')
        const updatedVote = type === 'inc' ? vote + 1 : vote - 1;
        return dispatch => {
            axios
                .put(
                    `/api/questions/${id}`,
                    { upvote: updatedVote },
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                )
                .then((res) => {
                    console.log(res.data);
                    dispatch(updatedQuestionVoteSuccess(res.data.data.upvote))
                })
                .catch((err) => {
                    dispatch(updatedQuestionVoteFail())
                    console.log(err.response.data)
                });
        }
    } else {
        return dispatch => {
            dispatch(alreadyVoted("You have already voted !!!"));
        }
    }

}

// answers vote related
export const updatedQuestionAnswerVoteSuccess = (updatedVote, index) => {
    return {
        type: ACTIONS.UPDATE_QUESTION_ANSWERS_VOTE_SUCCESS,
        payload: {
            updatedVote,
            index
        }
    }
}

export const updatedQuestionAnswerVoteFail = () => {
    return {
        type: ACTIONS.UPDATE_QUESTION_ANSWERS_VOTE_FAIL
    }
}
export const updateQuestionAnswerVote = (id, vote, index, isVoted, type) => {
    if (!isVoted) {
        const token = localStorage.getItem('token')
        const updatedVote = type === 'inc' ? vote + 1 : vote - 1;
        return dispatch => {
            axios
                .put(
                    `/api/answers/${id}`,
                    { upvote: updatedVote },
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                )
                .then((res) => {
                    console.log(res.data);
                    dispatch(updatedQuestionAnswerVoteSuccess(res.data.data.upvote, index))
                })
                .catch((err) => {
                    dispatch(updatedQuestionAnswerVoteFail())
                    console.log(err.response.data)
                });
        }
    } else {
        return dispatch => {
            dispatch(alreadyVotedAnswer("You have already voted!!!"))
        }
    }

}

// post question modal close
export const postQuestionModalClose = () => {
    return {
        type: ACTIONS.POST_QUESTION_MODAL_CLOSE
    }
}

// already voted answer modal close
export const alreadyVotedAnswerModalClose = () => {
    return {
        type: ACTIONS.ALREADY_VOTED_ANSWER_MODAL_CLOSE
    }
}

export const alreadyVotedQuestionModalClose = () => {
    return {
        type: ACTIONS.ALREADY_VOTED_QUESTION_MODAL_CLOSE
    }
}