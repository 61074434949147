import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Hero from './Hero'
import Footer from './partials/Footer'
import Header from './partials/Header'
import Questionlist from '../components/Question/Questionlist';
import QuestionListHeader from './Question/QuestionListHeader';

import { fetchQuestions } from '../store/actions/questions'

function Home(props) {
  useEffect(() => {
    props.onFetchQuestions();
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <Header />
      <Hero />
      <QuestionListHeader />
      <Questionlist />
      <Footer />
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchQuestions: () => dispatch(fetchQuestions())
  }
}

export default connect(null, mapDispatchToProps)(Home);